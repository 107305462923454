import {useEffect, useState} from "react";

import {getRequest} from "@pg-mono/request";
import {apiLink} from "@pg-mono/rp-api-routes";

import {IPropertyPlanProperty} from "../components/property_plan/PropertyPlan";
import {IPropertyDetail} from "../types/IPropertyDetail";
import {ISimhomeProperty} from "../types/isimhome_property";

interface IUseSimhomePropertyParams {
    rpProperty?: IPropertyWithPossiblePlan;
}

export type IPropertyWithPossiblePlan = Omit<IPropertyPlanProperty, "uuid"> & Pick<IPropertyDetail, "uuid">;

type IPropertyWithUuid = Omit<IPropertyDetail, "uuid"> & {uuid: string};

export function useSimhomeProperty(params: IUseSimhomePropertyParams) {
    const {rpProperty} = params;
    const [isSuccess, setIsSuccess] = useState<null | boolean>(null);
    const [simhomeProperty, setSimhomeProperty] = useState<null | ISimhomeProperty>(null);

    const isUuidAvailable = (property?: IPropertyWithPossiblePlan): property is IPropertyWithUuid => {
        return Boolean(property && property.uuid);
    };

    const isExternalPlanAvailable = (property?: IPropertyWithPossiblePlan): property is IPropertyWithUuid => {
        return Boolean(property && property.uuid && simhomeProperty && simhomeProperty.is_ready && simhomeProperty.area_verified);
    };

    const planFetchFailed = isUuidAvailable(rpProperty) && isSuccess === false;
    const fetchedPlanButIsNotReady = isUuidAvailable(rpProperty) && isSuccess && !isExternalPlanAvailable(rpProperty);
    const fetchedPlanAndIsReady = isUuidAvailable(rpProperty) && isSuccess && isExternalPlanAvailable(rpProperty);
    const rpPropertyWithExternalPlan = isExternalPlanAvailable(rpProperty) ? rpProperty : null;

    useEffect(() => {
        if (rpProperty && isUuidAvailable(rpProperty)) {
            const planApiLink = apiLink.planner.estate.detail({})({propertyUuid: rpProperty.uuid});

            getRequest({}, planApiLink)
                .then((res) => {
                    setSimhomeProperty(res as ISimhomeProperty);
                    setIsSuccess(true);
                })
                .catch(() => {
                    setIsSuccess(false);
                });
        }
    }, [rpProperty]);

    return {
        simhomeProperty,
        isLoading: isUuidAvailable(rpProperty) && isSuccess === null,
        showLegacyPlan: Boolean(!isUuidAvailable(rpProperty) || planFetchFailed || fetchedPlanButIsNotReady),
        showExternalPlan: Boolean(fetchedPlanAndIsReady && rpPropertyWithExternalPlan && simhomeProperty),
        rpPropertyWithExternalPlan
    };
}
