import React from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {flex, mb, ml, mr, ph, pointer, pt} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";

import {NavButton} from "../atoms/NavButton";
import {ModalHeader} from "../offer/components/gallery_holder/GalleryHolder";
import {getOfferTypeNameCapital} from "../offer/helpers/OfferType";
import {OfferType} from "../offer/types/OfferType";

interface IProps {
    offer: {
        is_condohotel?: boolean;
        is_holiday_location?: boolean;
        type: OfferType;
    };
    property: {
        number: string;
    } | null;
    index: number;
    onNextClick: (index: number) => Promise<void>;
    onPrevClick: (index: number) => Promise<void>;
    pageCount: number;
    currentPage: number;
    propertiesOnCurrentPage: number;
    pageIsLoading: boolean;
    onModalClose: () => void;
}
export const PropertyListPlanModalHeader: React.FC<IProps> = (props) => {
    const {property, offer, pageIsLoading} = props;

    const isApartment = offer.is_holiday_location || offer.is_condohotel;
    const propertyType = isApartment ? "Apartament" : getOfferTypeNameCapital(offer.type);

    const prevText = offer.type === OfferType.FLAT ? "Poprzednie" : "Poprzedni";
    const nextText = offer.type === OfferType.FLAT ? "Następne" : "Następny";

    const renderPrevButton = () => {
        const isFirstInQuery = props.index === 0 && props.currentPage === 1;

        const isDisabled = isFirstInQuery || pageIsLoading;

        return (
            <NavButton css={mr(2)} direction="prev" disabled={isDisabled} onClick={() => props.onPrevClick(props.index)}>
                {prevText}
            </NavButton>
        );
    };

    const renderNextButton = () => {
        const isLastOnPage = props.index === props.propertiesOnCurrentPage - 1;
        const isLastPage = props.currentPage === props.pageCount;

        const isDisabled = (isLastOnPage && isLastPage) || pageIsLoading;

        return (
            <NavButton css={ml(2)} direction="next" disabled={isDisabled} onClick={() => props.onNextClick(props.index)}>
                {nextText}
            </NavButton>
        );
    };

    return property ? (
        <PlansModalHeader>
            <div css={modalHeaderHolder}>
                {!pageIsLoading && (
                    <Text as="h1" variant="headline_5">
                        {propertyType} {property.number}
                    </Text>
                )}

                <div css={flex("center")}>
                    <div css={navButtonsHolder}>
                        {renderPrevButton()}
                        {renderNextButton()}
                    </div>

                    <CloseIcon css={pointer} onClick={props.onModalClose} fill="#fff" size="2" />
                </div>
            </div>
        </PlansModalHeader>
    ) : null;
};

const PlansModalHeader = styled(ModalHeader)`
    ${pt(3)};
    ${ph(2)};
    ${mb(4)};

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        ${pt(4)};
        ${ph(3)};
        ${mb(5)};
    }
`;

const modalHeaderHolder = (props: Theme) => css`
    ${flex("center", "space-between")};
    width: 100%;

    @media (min-width: ${props.breakpoints.md}) {
    }
`;

const navButtonsHolder = (props: Theme) => css`
    display: flex;
    flex-grow: 0;
    ${mr(3)};

    @media (min-width: ${props.breakpoints.md}) {
        ${mr(6)};
    }
`;
