import {pluralize} from "@pg-mono/string-utils";

import {OfferType} from "../../offer/types/OfferType";
import {IPropertyDetail} from "../types/IPropertyDetail";

export type IPropertyWithFloorInfo = Pick<IPropertyDetail, "floor" | "floors"> & {
    offer: Pick<IPropertyDetail["offer"], "type">;
};

interface IPropertyFloorName {
    number: number;
    text: string;
}

export function getPropertyFloorTextData(property: IPropertyWithFloorInfo): IPropertyFloorName {
    if (property.offer.type === OfferType.HOUSE && property.floors) {
        return getHouseFloorName(property);
    } else {
        return getPropertyFloorName(property);
    }
}

//  Utils
type IHouseProperty = IPropertyWithFloorInfo & {offer: {type: OfferType.HOUSE}};

function getHouseFloorName(property: IHouseProperty): IPropertyFloorName {
    const houseFloorsPlural = pluralize(["poziom", "poziomy", "poziomów"]);

    return {
        number: property.floors,
        text: houseFloorsPlural(property.floors)
    };
}

type IRegularProperty = IPropertyWithFloorInfo & {offer: {type: OfferType.FLAT | OfferType.COMMERCIAL}};

function getPropertyFloorName(property: IRegularProperty): IPropertyFloorName {
    return {
        number: property.floor,
        text: property.floor === 0 ? "parter" : "piętro"
    };
}
